<template>
    <Toast />
    <ConfirmPopup></ConfirmPopup>
    <div class="grid">  
        <div class="col-12"> <Message :closable="false" severity="warn" ><h5 class="m-0 p-15">You will be logged out automatically on successfull submission any of the form below</h5></Message></div>
	</div>
</template>

<script>
export default {
    data() { 
        return {   
        }
    },
    beforeCreate(){
        
    },
    mounted(){   
    },
    methods:{  
    }
}
</script>